import { Link, navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { setAddDiscountAction, setAddProductAction } from '../../redux/cart/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL, CDN_URL } from '../../../config';
import axios from 'axios';
import { formatCurrency } from '../../utils';
import ReactPlayer from 'react-player';
import ModalComponent from '../modal';
import { useTranslation } from 'react-i18next';
const CourseDetailsSidebar = ({courseDetails, location}) => {

    const [timeRemaining, setTimeRemaining] = useState(null);

    useEffect(() => {
        
        const timer = setInterval(() => {
        setTimeRemaining(getTimeRemaining());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const { t, i18n } = useTranslation();

    const {userData, isAuth} = useSelector(state=> state.auth);
    const searchParams = new URLSearchParams(location.search)
    const couponSearch = searchParams.get("couponCode");
    const cart = useSelector(state=> state.cart);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [enrrolled, setEnrolled] = React.useState(false)
    const [showCoupon, setShowCoupon] = React.useState(false)
    const [loadingCoupon, setLoadingCoupon] = React.useState(false)
    const [coursePrice, setCoursePrice] = React.useState(courseDetails.priceNow)
    const [couponError, setCouponError] = React.useState(null)

    const [code, setCode] = useState(null)
   const [usedCoupon, setUsedCoupon] = useState(false)
   const [discountValue, setDiscountValue] = useState(0)



   React.useEffect(()=>{
    if(couponSearch){
        setShowCoupon(true)
        setCode(couponSearch)
        handleCheckCoupon(couponSearch);
    }
   },[couponSearch])
    
    const [modalShow, setModalShow] = useState(false);
    
    const dispatch = useDispatch();

    React.useEffect(()=>{
        if(discountValue > 0){
            let discountAmount = courseDetails.priceNow * (discountValue / 100);
            let discountPrice =  courseDetails.priceNow - discountAmount
            setCoursePrice(discountPrice);
        }
        
    },[discountValue])

    React.useEffect(()=>{
        if(isAuth){
            checkEnrolledCourse();
        }
        
    },[isAuth])

    
    const checkEnrolledCourse = async () => {
        setLoading(true)
        try {
            const result = await axios.get(BASE_URL+"/students/"+userData.id)
            const isEnrolled = result.data.enrolledCourses.some(data => data.course.id === courseDetails.id);
            setEnrolled(isEnrolled)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    

    const handleAddProduct = (data) => {
        console.log("mostrando data", data)
        let discountAmount = data.priceNow * (discountValue / 100);

        const payload = {
          id: data.id,
          slug: data.slug,
          image: data.introImage,
          name: data.name,
          cant: 1,
          price: parseFloat(data.priceNow) - parseFloat(discountAmount),
          coupon: code,
          discount: discountValue
        }
        
        dispatch(setAddProductAction(payload))

        if (typeof window !== 'undefined') {
            //Add event add_to_cart
            window.dataLayer.push({
                event: "add_to_cart",
                currency: "USD",
                value: parseFloat(data.priceNow) - parseFloat(discountAmount),
                items: [
                    {
                    item_id: data.id,
                    item_name: data.name,
                    coupon: code,
                    discount: discountValue,
                    price: parseFloat(data.priceNow),
                    quantity: 1
                    }
                ]
            });

        }
        
    }

    const handleCheckCoupon = async (couponValue) => {
        try {
            setLoadingCoupon(true)
            const payload = {
                code: code ? code : couponValue,
                courseId: courseDetails.id 
            }
            const {data} = await axios.post(BASE_URL+"/coupons/validate", payload)
            const {discount} = data;
            setLoadingCoupon(false)
            setUsedCoupon(true)
            setDiscountValue(discount)
        } catch (error) {
            setLoadingCoupon(false)
            setCouponError("Cupón expirado o no válido");
        }
       }


       const getTimeRemaining = () => {
        // Ajusta la fecha de finalización de la promoción
        const promotionEndDate = new Date('2024-02-08T00:00:00Z');
        const currentTime = new Date();
        
        let difference = Math.max(promotionEndDate - currentTime, 0);
    
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        
        if(location.pathname.includes('despliega-tus-desarrollos-nodejs-a-un-servidor-vps-en-produccion')){
            return { hours, minutes, seconds };    
        }
        return null;
      }
       
      

    return (
        <div className="course-video-widget">
            <div className="course-widget-wrapper mb-30">
                <ModalComponent show={modalShow} handleClose={() => setModalShow(false)}>
                    {courseDetails.introVideo &&
                    <ReactPlayer
                    className='apprende-player'
                    url={`${CDN_URL}/hls/output/${courseDetails.introVideo}/${courseDetails.introVideo}_1080p.m3u8`}
                    controls={true}
                    width="100%"
                    height="auto"
                    playing
                    config={{ hls: { withCredentials: false } }}
                />
                    }
                </ModalComponent>
                
                <div className="course-video-thumb w-img">
                    <img src={courseDetails.introImage} alt="introImage" />
                    <div className="sidber-video-btn">
                        <span className="popup-video" onClick={() => { setModalShow(true); }}><i className="fas fa-play"></i></span>
                    </div>
                </div>
                <div className="course-video-price">
                    {courseDetails.isFree && <span>{t('FREE')}</span>}
                    {!courseDetails.isFree &&
                    <>
                    <span>USD ${formatCurrency(coursePrice)}</span> <br/>
                    {courseDetails.priceOld > 0 && <del>USD ${formatCurrency(courseDetails.priceOld)}</del>}
                    </>
                    }
                    {timeRemaining && 
                    <div className='mt-5'>
                    <p>{t('ValidPromo')}</p>
                    <span>{timeRemaining.hours}h {timeRemaining.minutes}m {timeRemaining.seconds}s </span>
                    </div>
                    }
                    
                    
                </div>
                {usedCoupon && <span>USD $ <del>{formatCurrency(courseDetails.priceNow)}</del></span>}
                <div className="course-video-body">
                    <ul>
                        <li>
                            <div className="course-vide-icon">
                                <i className="flaticon-filter"></i>
                                <span>{t('Level')}</span>
                            </div>
                            <div className="video-corse-info">
                                <span>{courseDetails.level}</span>
                            </div>
                        </li>
                        <li>
                            <div className="course-vide-icon">
                                <i className="flaticon-computer"></i>
                                <span>{t('Lessons')}</span>
                            </div>
                            <div className="video-corse-info">
                                <span>{courseDetails.countLessons}</span>
                            </div>
                        </li>
                        <li>
                            <div className="course-vide-icon">
                                <i className="flaticon-computer"></i>
                                <span>Videos</span>
                            </div>
                            <div className="video-corse-info">
                                <span>{courseDetails.countVideos}</span>
                            </div>
                        </li>
                        {/* <li>
                            <div className="course-vide-icon">
                                <i className="flaticon-clock"></i>
                                <span>Duración</span>
                            </div>
                            <div className="video-corse-info">
                                <span>1h 30m 12s</span>
                            </div>
                        </li> */}
                        <li>
                            <div className="course-vide-icon">
                                <i className="flaticon-menu-2"></i>
                                <span>{t('Category')}</span>
                            </div>
                            <div className="video-corse-info">
                                <span>{courseDetails.subcategory?.name}</span>
                            </div>
                        </li>
                        <li>
                            <div className="course-vide-icon">
                                <i className="flaticon-global"></i>
                                <span>{t('Language')}</span>
                            </div>
                            <div className="video-corse-info">
                                <span>{courseDetails.language}</span>
                            </div>
                        </li>
                        <li>
                            <div className="course-vide-icon">
                                <i className="flaticon-bookmark-white"></i>
                                <span>{t('Access')}</span>
                            </div>
                            <div className="video-corse-info">
                                <span>{t('Lifetime')}</span>
                            </div>
                        </li>
                        <li>
                            <div className="course-vide-icon">
                                <i className="flaticon-award"></i>
                                <span>{t('Certificate')}</span>
                            </div>
                            <div className="video-corse-info">
                                <span>{courseDetails.certificate ? t('Yes') : t('No') }</span>
                            </div>
                        </li>
                        <li>
                            <div className="course-vide-icon">
                                <i className="flaticon-list"></i>
                                <span>{t('DownloadableAssets')}</span>
                            </div>
                            <div className="video-corse-info">
                                <span>No</span>
                            </div>
                        </li>
                    </ul>
                </div>

                {!showCoupon && !enrrolled && !courseDetails.isFree && 
                <div className="course-gift mb-5">
                    <div className="course-apply-coupon">
                        <a href="#" onClick={()=>setShowCoupon(prev => !prev)}>¿{t('HasADiscountCoupon')}?</a>
                    </div>
                </div>
                }
                {showCoupon &&
                <div className="row">
                    <div className="col-12">
                        <div className="coupon-all">
                            <div className="coupon d-flex align-items-center">
                                <input id="coupon_code" className="form-control" name="coupon_code" value={code} onChange={(e)=>setCode(e.target.value)} readOnly={usedCoupon} disabled={usedCoupon} placeholder="Cupón" type="text" />
                                {!usedCoupon && <button className="btn btn-warning mx-2" onClick={()=>handleCheckCoupon()} disabled={loadingCoupon} type="button">{loadingCoupon ? t('Loading') : t('Apply')}</button>} <br/>
                                
                            </div>
                            {couponError && 
                            <div className="coupon d-flex align-items-center">
                                <div className='text-danger'>{couponError}</div>
                            </div>
                            }
                            
                        </div>
                    </div>
                </div>
                }

                <div className="video-wishlist mt-20">
                {!enrrolled && !loading && !cart.products.map(data=> data.id).includes(courseDetails.id) && <button type="button" onClick={()=>handleAddProduct(courseDetails)} id="add-to-cart" className="video-cart-btn"><i className="fal fa-shopping-cart"></i>{t('AddToCart')}</button>}
                {!enrrolled && !loading && cart.products.map(data=> data.id).includes(courseDetails.id) && <button type="button" onClick={()=>navigate('/cart')} id="go-to-cart" className="video-cart-btn"><i className="fal fa-shopping-cart"></i>{t('GoToCart')}</button>}
                {enrrolled  && !loading && <Link to={"/course-video/"+courseDetails.id} className="video-cart-btn"><i className="fal fa-video"></i>{t('GoToCourse')}</Link>}
                    {/* <Link to="/wishlist" className="video-wishlist-btn"><i className="far fa-heart"></i>Añadir a mis deseos</Link> */}
                </div>
                <>
                
                </>
            </div>
        </div>
    );
};

export default CourseDetailsSidebar;