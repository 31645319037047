import { Link } from 'gatsby';
import React, { useState } from 'react';
import CourseCurriculam from './courseCurriculam';
import CourseDetailsInstructor from './courseDetailsInstructor';
import CourseDetailsSidebar from './courseDetailsSidebar';
import CourseProgressbar from './courseProgressbar';
import { BASE_URL } from '../../../config';
import axios from 'axios'
import StarRating from '../starRating';
import { useTranslation } from 'react-i18next';
import StudentFeedbackComponent from '../studentFeedback';

const CourseDetailsSection = ({courseDetails,location}) => {
    const [isActive, setActive] = useState("false");

    const { t, i18n } = useTranslation();

    const handleToggle = () => {
        setActive(!isActive);
    };

    React.useEffect(()=>{

        if (typeof window !== 'undefined') {
        
            //Send event to GTM
            window.dataLayer.push({
              event: "view_item",
              currency: "USD",
              value: parseFloat(courseDetails.priceNow),
              items: [
                {
                    item_id: courseDetails.id,
                    item_name: courseDetails.name,
                    price: parseFloat(courseDetails.priceNow),
                    quantity: 1
                }
              ]
            });
            
          }

    },[])

    
    return (
        <section className="course-detalis-area">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-8 col-xl-8 order-last order-xl-first">
                        <div className="course-detalis-wrapper mb-30">
                            <div className="course-heading mb-20">
                                <h2>{courseDetails.name}</h2>
                                <StarRating rating={courseDetails.rating} count={courseDetails.countReviews} />
                            </div>
                            <div className="course-detelis-meta">
                                <div className="course-meta-wrapper border-line-meta">
                                    <div className="course-meta-img">
                                        <Link to={"/instructor-profile/"+courseDetails.teacher.id}><img src={courseDetails.teacher.photo} alt="course-meta" /></Link>
                                    </div>
                                    <div className="course-meta-text">
                                        <span>{t('Teacher')}</span>
                                        <h6><Link to={"/instructor-profile/"+courseDetails.teacher.id}>{courseDetails.teacher.firstName}</Link></h6>
                                    </div>
                                </div>
                                <div className="course-Enroll border-line-meta">
                                    <p>{t('StudentsInTheCourse')}</p>
                                    <span>{courseDetails.enrolledStudents}</span>
                                </div>
                                <div className="course-update border-line-meta">
                                    <p>{t('LastUpdate')}</p>
                                    <span>{new Date(courseDetails.updatedAt).toLocaleDateString('es-Es')} </span>
                                </div>
                                <div className="course-category">
                                    <p>{t('Category')}</p>
                                    <span>{courseDetails.subcategory?.name}</span>
                                </div>
                            </div>
                            <div className="course-description pt-45 pb-30">
                                <div className="course-Description">
                                    <h4>{t('CourseDescription')}</h4>
                                </div>
                                <div dangerouslySetInnerHTML={{__html: courseDetails.description}}>

                                </div>
                            </div>
                            <div className="course-learn-wrapper">
                                <div className="course-learn">
                                    <div className="course-leranm-tittle">
                                        <h4 className="mb-15">¿{t('WhatAreYouGoingToLearn')}?</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="course-leran-text f-left">
                                            <ul>
                                                {courseDetails.willLearn.map((data, index) => (
                                                <li key={index}>{data}</li>
                                                ))}
                                            </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="course-requirements pt-45">
                                <h4>{t('Requirements')}</h4>
                                <div className="course-requirements-text">
                                    <ul>
                                        {courseDetails.requirements.map((data, index) => (
                                            <li key={index}>• {data}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <CourseCurriculam courseDetails={courseDetails}/>
                            <CourseDetailsInstructor courseDetails={courseDetails}/>
                            {/* <StudentFeedbackComponent/> */}
                            <div className="course-detalis-reviews pt-15">
                            {courseDetails.reviews && courseDetails.reviews.length > 0 &&
                                <div className="course-detalis-reviews-tittle">
                                    <h3>{t('Comments')}</h3>
                                </div>
                            }
                                
                                {courseDetails.reviews && courseDetails.reviews.length > 0 && courseDetails.reviews.map((data,index)=>{
                                    return(
                                <div key={data.id} className="course-review-item mb-30">
                                    <div className="course-reviews-img">
                                        <a href="#"><img src={data.student.photo} style={{width: '50px', borderRadius: '50px'}} alt="img not found" /></a>
                                    </div>
                                    <div className="course-review-list">
                                        <h5><a href="#">{data.student.firstName} {data.student.lastName}</a></h5>
                                        <StarRating rating={data.stars} />
                                        <p>{data.comment}</p>
                                    </div>
                                </div>
                                    )
                                })}
                            </div>                            
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-8 col-md-8 order-first order-xl-last">
                        <CourseDetailsSidebar courseDetails={courseDetails} location={location}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CourseDetailsSection;