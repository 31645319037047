import axios from 'axios';
import React from 'react';
import { BASE_URL } from '../../../config';
import { useTranslation } from 'react-i18next';

const CourseCurriculam = ({ courseDetails }) => {

    const { t, i18n } = useTranslation();
    
    
    return (
        <div className="course-curriculum pt-40 pb-50">
            <div className="course-curriculam">
                <h4>{t('CourseContent')}</h4>
            </div>
            <ul>
            </ul>
            <div className="course-curriculam-accodion mt-30">
                <div className="accordion" id="accordionExample">
                    {courseDetails.lessons && courseDetails.lessons.sort((a,b)=> a.order - b.order).map((lesson, index) => (
                        <div className="accordion-item" key={index}>
                            <div className="accordion-body" id={`heading${index}`}>
                                <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded={true}
                                    aria-controls={`collapse${index}`}
                                >
                                    <span className="accordion-header">
                                        <span className="accordion-tittle">
                                            <span>{lesson.name}</span>
                                        </span>
                                        <span className="accordion-tittle-inner">
                                            <span>{lesson.duration}</span>
                                        </span>
                                    </span>
                                </button>
                            </div>
                            <div
                                id={`collapse${index}`}
                                className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                aria-labelledby={`heading${index}`}
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    {lesson.videos.map((video, videoIndex) => (
                                        <div
                                            className="course-curriculum-content d-flex justify-content-between align-items-center"
                                            key={videoIndex}
                                        >
                                            <div className="course-curriculum-info">
                                                <i className="flaticon-youtube"></i>
                                                <h4>{video.name}</h4>
                                            </div>
                                            <div className="course-curriculum-meta">
                                                <span>{video.duration}</span>
                                                <span className="time"> <i className="flaticon-lock"></i></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CourseCurriculam;