import React, {useEffect,useState} from 'react';
import { graphql } from 'gatsby';
import Breadcrumb from '../common/breadcrumb';
import Footer from '../footer/footer';
import HeaderFour from '../header/headerFour';
import CourseDetailsSection from './courseDetailsSection';
import Layout from '../layout';
import { Seo } from '../seo';
import { useTranslation } from 'react-i18next';

const CourseDetailsMain = ({data, location}) => {
    
  const {t} = useTranslation();
  const courseDetails = data.RootQuery.course

    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    

    useEffect(() => {
      // Función para actualizar el estado con la altura y anchura de la ventana
      function handleResize() {
          setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
          });
      }
      
      // Agregar el event listener
      window.addEventListener('resize', handleResize);
      
      // Llamar a handleResize inmediatamente para actualizar el estado inicial
      handleResize();

      // Eliminar el event listener en el cleanup del componente
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Layout>
      
      <HeaderFour />
      <main>
        {courseDetails && (
          <>
          {windowSize.width > 768 && <Breadcrumb breadcrumbTitle={t('CourseDetails')} breadcrumbSubTitle={courseDetails.name}/>}
            
            <Seo
              title={courseDetails.name}
              description={courseDetails.shortDescription}
              image={courseDetails.introImage}
            />
            <CourseDetailsSection courseDetails={courseDetails} location={location} />
          </>
        )}
      </main>
      <Footer />
    </Layout>
  );
};

export default CourseDetailsMain;

export const query = graphql`
  query($id: String!, $language: String!) {
    RootQuery {    
      course(id: $id) {
        id
        name
        slug
        introImage
        introVideo
        shortDescription
        description
        willLearn
        priceNow
        priceOld
        level
        requirements
        updatedAt
        language
        isFree
        rating
        countReviews
        countVideos
        countLessons
        lessons {
          name
          order
          videos {
            name
          }
        }
        subcategory {
          name
        }
        teacher {
          id
          photo
          firstName
          lastName          
        }
        reviews {
          id
          comment
          stars
          student {
            photo
            firstName
            lastName            
          }         
          createdAt 
        }
        enrolledStudents
      }
    },
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
