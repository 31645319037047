import axios from 'axios';
import { Link } from 'gatsby';
import React from 'react';
import { BASE_URL } from '../../../config';
import { useTranslation } from 'react-i18next';

const CourseDetailsInstructor = ({courseDetails}) => {

    const { t, i18n } = useTranslation();
    
    const [teacher, setTeacher] = React.useState(null)

    React.useEffect(()=>{
        getTeacher();
    },[])

    const getTeacher = async () => {
        try {
            const result = await axios.get(BASE_URL+"/teachers/"+courseDetails.teacher.id)
            setTeacher(result.data);
        } catch (error) {
            
        }
    }
    return (
        <>
        {teacher &&
            <>
            <div className="course-instructors">
            <h3>{t('Teacher')}</h3>
            <div className="instructors-heading">
                <div className="instructors-img w-img">
                    <Link to={"/instructor-profile/"+teacher.id}><img src={teacher.photo} style={{width: '50px', borderRadius: '50px'}} alt="teacher photo" /></Link>
                </div>
                <div className="instructors-body">
                    <h5><Link to={"/instructor-profile/"+teacher.id}>{teacher.firstName} {teacher.lastName}</Link></h5>
                    <span>{teacher.title}</span>
                    <div className="instructors-footer">
                        {/* <i className="fas fa-desktop"></i>
                        <span>{teacher.courses.length} Cursos</span> */}
                        {/* <i className="far fa-user-friends"></i>
                        <span>{teacher.students.length} Estudiantes</span> */}
                    </div>
                </div>
            </div>
            <div className="intructors-content">
                <p>{courseDetails.teacher.bio}</p>
            </div>
        </div>
        </>
        }
        </>
        
    );
};

export default CourseDetailsInstructor;